import { Component, OnInit } from '@angular/core';
import { Router }            from '@angular/router';

import { HubsService } from '@services/hubs.service';
import { AuthUser }    from '@models/user/auth-user';


@Component({
  selector:    'red-redirect',
  templateUrl: './redirect.component.html',
  styleUrls:   [ './redirect.component.scss' ]
})
export class RedirectComponent implements OnInit {

  constructor(
    private hubsService: HubsService,
  ) { }

  private static checkRoute(user: AuthUser, router: Router) {

    switch (user.perfil) {
      case 'SUPER_ADMIN':
      case 'ADMIN':
        router.navigate([ '/dash/admin' ]).then();
        break;

      case 'PROFESSOR':
      case 'CORRETOR_SAAS':
        router.navigate([ '/correcao' ]).then();
        break;

      case 'COORDENADOR_ESCOLA':
      case 'COORDENADOR_TURMA':
        if (user.escolaPlanoPrePago) {
          router.navigate([ '/dash/coordenador' ]).then();
        } else {
          router.navigate([ '/tarefa/listaTarefa' ]).then();
        }
        // router.navigate([ '/maratona' ]).then();
        break;

      case 'COORDENADOR_SAAS':
        router.navigate([ '/tarefa/listaTarefa' ]).then();
        // router.navigate([ '/maratona' ]).then();
        break;

      case 'ALUNO':
      case 'ALUNO_B2C':
        router.navigate([ '/redacao/lista' ]).then();
        // router.navigate([ '/maratona' ]).then();
        break;

      case 'ASSESSOR':
        router.navigate([ '/dash/assessor' ]).then();
        break;

      default:
        // do nothing
    }

  }

  ngOnInit(): void {
    this.hubsService.checkUserType(RedirectComponent.checkRoute);
  }

}
