import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable }                                from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map }            from 'rxjs/operators';

import { environment }                    from '@environment/environment';
import { TransformClass }                 from '@utils/transform';
import { AuthUser }                       from '@models/user/auth-user';
import { RestResponse }                   from '@models/rest';
import { RestPaged }                      from '@models/rest/rest-paged';
import { RestPaginationAndSortingParams } from '@models/rest/rest-pagination-and-sorting-params';
import { SearchProofreaderFilters }       from '@models/proofreader/search-proofreader-filters';
import { Proofreader }                    from '@models/proofreader/proofreader';
import { AuthParams }                     from '@models/user/auth-params';
import { SignUpParams }                   from '@models/user/sign-up-params';
import { ChangePasswordParams }           from '@models/user/change-password-params';
import { PidAuthParams }                  from '@models/user/pid-auth-params';
import { User }                           from '@models/user/user';
import { UsersBatch }                     from '@models/user/users-batch';
import { UserTypeRedux }                  from '@models/user/user-type';
import { ProofreaderQueueOption }         from '@models/user/proofreader-queue-option';
import { ProofreaderProfileOption }       from '@models/user/proofreader-profile-option';
import { ProofreaderCategoryOption }      from '@models/user/proofreader-category-option';
import { SearchAdvisorFilters }           from '@models/advisors/search-advisors-filters';
import { AdvisorsItem }                   from '@models/advisors/advisors';
import { ProofreaderTrainingOption }      from '@models/user/proofreader-training-option';
import { GetUsersParams }                 from '@models/user/get-users-params';
import { UserReport }                     from '@models/user/user-report';
import { UserOption }                     from '@models/user/user-option';
import { ChangeManagerProfileParams }     from '@models/user/change-proofreader-school-class';
import { UserAdvisor }                    from '@models/user/user-advisor';
import { ProofreaderSuspensionData }      from '@models/user/proofreader-suspension-data';
import { Menu }                           from '@models/navigation/menu';
import { CategoryProfile }                from '@shared/models/proofreader/category-profile';
import { ChangeSaasProfileParams }        from '@models/user/change-saas-profile-params';
import { ProofreaderSuspensionType }      from '@models/user/proofreader-suspension-type';
import { UserNetwork }                    from '@models/user/user-network';


@Injectable({ providedIn: 'root' })
export class ApiUserService {

  private readonly baseUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = `${ environment.apiUrl }`;
  }

  public signUpStudent(params: SignUpParams): Observable<any> {
    return this.http.post(`${ this.baseUrl }/aluno`, params).pipe(
      map((response: RestResponse) => response.data)
    );
  }

  public handleAuthenticationError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error);
  }

  public pidAuthenticate(params: PidAuthParams): Observable<AuthUser> {
    return this.http.post<RestResponse>(`${ this.baseUrl }/autenticacao/pid`, params).pipe(
      catchError((error: HttpErrorResponse) => this.handleAuthenticationError(error)),
      map((response: RestResponse) => response.data as AuthUser)
    );
  }

  public authenticate(params: AuthParams): Observable<AuthUser> {
    return this.http.post(`${ this.baseUrl }/autenticacao`, params).pipe(
      catchError((error: HttpErrorResponse) => this.handleAuthenticationError(error)),
      map((response: RestResponse) => response.data as AuthUser)
    );
  }
 
  public oneClickLogin(userId: number): Observable<AuthUser> {
    const httpParams: HttpParams = TransformClass.httpParams({ usuarioId: userId });

    return this.http.post<RestResponse>(
      `${ this.baseUrl }/support/atendimento`,
      {}, { params: httpParams }
    ).pipe(
      catchError((error: HttpErrorResponse) => this.handleAuthenticationError(error)),
      map((response: RestResponse) => response.data as AuthUser)
    );
  }

  public getUser(token: string): Observable<AuthUser> {
    if (!token) return of(null);

    const httpParams: HttpParams = TransformClass.httpParams({ jwtToken: token });

    return this.http.get(`${ this.baseUrl }/autenticacao`, { params: httpParams }).pipe(
      map((response: RestResponse) => response.data as AuthUser),
      catchError((error: HttpErrorResponse) => this.handleAuthenticationError(error))
    );
  }

  public acceptContract(): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/aceiteContrato`, {});
  }

  public requestPasswordChange(email: string): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/senha/redefinicao`, { login: email });
  }

  public changePassword(params: ChangePasswordParams): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/senha`, params);
  }

  public changeCurrentUserPassword(params: ChangePasswordParams): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/senha/logado`, params);
  }

  public getUsers(
    searchParams: GetUsersParams
  ): Observable<UserReport<UserTypeRedux>[]> {

    const httpParams: HttpParams = TransformClass.httpParams(searchParams);

    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario`, { params: httpParams }
    ).pipe(map((response: RestResponse) => response.data as UserReport<UserTypeRedux>[]));
  }

  public getUserForEdit(id: number): Observable<User<UserTypeRedux>> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/usuario/${ id }`).pipe(
      map((response: RestResponse) => response.data as User<UserTypeRedux>)
    );
  }

  public getCurrentUserForEdit(): Observable<User<UserTypeRedux>> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/usuario/logado`).pipe(
      map((response: RestResponse) => response.data as User<UserTypeRedux>)
    );
  }

  public signUpUser(user: User<UserTypeRedux>): Observable<User<UserTypeRedux>> {
    return this.http.post<RestResponse>(`${ this.baseUrl }/usuario`, user).pipe(
      map((response: RestResponse) => response.data as User<UserTypeRedux>)
    );
  }

  public signUpBatchUsers(batch: UsersBatch<UserTypeRedux>): Observable<UsersBatch<UserTypeRedux>> {
    return this.http.post<RestResponse>(`${ this.baseUrl }/usuario/lote`, batch).pipe(
      map((response: RestResponse) => response.data as UsersBatch<UserTypeRedux>)
    );
  }

  public searchProofreaders(
    filterParams:     SearchProofreaderFilters,
    paginationParams: RestPaginationAndSortingParams = {}
  ): Observable<RestPaged<Proofreader[]>> {

    const httpParams: HttpParams = TransformClass.httpParams({
      ...filterParams,
      ...TransformClass.formatPaginationParams(paginationParams)
    });

    return this.http.get<RestPaged<Proofreader[]>>(
      `${ this.baseUrl }/usuario/professores`, { params: httpParams }
    );
  }

  public searchAdvisors(
    filterParams:     SearchAdvisorFilters,
    paginationParams: RestPaginationAndSortingParams = {}
  ): Observable<RestPaged<AdvisorsItem[]>> {
    const httpParams: HttpParams = TransformClass.httpParams({
      ...filterParams,
      ...TransformClass.formatPaginationParams(paginationParams)
    });

    return this.http.get<RestPaged<AdvisorsItem[]>>(
      `${ this.baseUrl }/usuario/assessor`, { params: httpParams }
    );
  }

  public getUserNetworks(): Observable<UserNetwork[]> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/usuario/redes`).pipe(
      map((response: RestResponse) => response.data as UserNetwork[])
    );
  }

  public getAllProofreaderProfiles(): Observable<string[]> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/usuario/professor/perfis`).pipe(
      map((response: RestResponse) => response.data as string[])
    );
  }

  public getProofreaderProfiles(id: number): Observable<ProofreaderProfileOption[]> {
    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario/professor/${ id }/perfis`
    ).pipe(map((response: RestResponse) => response.data as ProofreaderProfileOption[]));
  }

  public updateProofreaderProfiles(id: number, profileIds: string[]): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/professor/${ id }/perfis`, profileIds);
  }

  public updateProofreaderSituation(id: number, active: boolean): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/${ id }/situacao/${ active }`, {});
  }

  public getProofreaderQueues(id: number): Observable<ProofreaderQueueOption[]> {
    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario/professor/${ id }/filas`
    ).pipe(map((response: RestResponse) => response.data as ProofreaderQueueOption[]));
  }

  public updateProofreaderQueues(id: number, queueIds: string[]): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/professor/${ id }/filas`, queueIds);
  }

  public getProofreaderCategories(id: number): Observable<ProofreaderCategoryOption[]> {
    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario/professor/${ id }/categorias`
    ).pipe(map((response: RestResponse) => response.data as ProofreaderCategoryOption[]));
  }

  public updateProofreaderCategories(id: number, categoryIds: number[]): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/professor/${ id }/categorias`, categoryIds);
  }

  public getProofreaderTrainings(id: number): Observable<ProofreaderTrainingOption[]> {
    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario/professor/${ id }/treinamentos`
    ).pipe(map((response: RestResponse) => response.data as ProofreaderTrainingOption[]));
  }

  public updateProofreaderTrainings(id: number, trainingIds: number[]): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/professor/${ id }/treinamentos`, trainingIds);
  }

  // public suspendProofreader(
  //   proofreaderId: number,
  //   until:         string // Data de validade da suspensão (DD/MM/YYYY)
  // ): Observable<void> {
  //   const httpParams: HttpParams = TransformClass.httpParams({ datValidade: until });

  //   return this.http.put<void>(
  //     `${ this.baseUrl }/usuario/professor/${ proofreaderId }/suspensao`, {},
  //     { params: httpParams }
  //   );
  // }

  public suspendProofreader(
    proofreaderId:  number,
    until:          string, // Data de validade da suspensão (DD/MM/YYYY)
    suspensionType: ProofreaderSuspensionType,
    observation?:   string
  ): Observable<void> {
    const httpParams: HttpParams = TransformClass.httpParams({
      professorId:            proofreaderId,
      datValidade:            until,
      professorTipoSuspensao: suspensionType,
      observacao:             observation
    });

    return this.http.put<void>(
      `${ this.baseUrl }/usuario/professor/suspensao`, {},
      { params: httpParams }
    );
  }

  public cancelProofreaderSuspension(
    proofreaderId:      number,
    motivoCancelamento: string
  ): Observable<void> {
    return this.http.put<void>(
      `${ this.baseUrl }/usuario/professor/${ proofreaderId }/suspensao`,
      { motivoCancelamento }
    );
  }

  public getProofreaderHistory(proofreaderId: number): Observable<ProofreaderSuspensionData[]> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/usuario/${ proofreaderId }/suspensoes`).pipe(
      map((response: RestResponse) => response.data as ProofreaderSuspensionData[])
    );
  }

  public changeManagerProfile(manager: ChangeManagerProfileParams): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/coordenador/mudancaPerfil`, manager);
  }

  public changeSaasProfile(params: ChangeSaasProfileParams): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/usuario/professor/saas/mudancaPerfil`, params);
  }

  public getPosition(): Observable<string[]> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/usuario/coordenador/cargos`)
    .pipe(map((response: RestResponse) => response.data as string[]));
  }

  public searchAdmins(
    search: string,
    size:   number = 1000
  ): Observable<UserOption[]> {

    const httpParams: HttpParams = TransformClass.httpParams({
      search, size
    });

    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario/lista/admin`, { params: httpParams }
    ).pipe(map((response: RestResponse) => response.data as UserOption[]));
  }

  public searchAdvisor(
    search: string,
    size:   number = 1000,
    active: boolean): Observable<UserAdvisor[]> {
    const httpParams: HttpParams = TransformClass.httpParams({
      search, size, active
    });

    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario/lista/assessores`, { params: httpParams }
    ).pipe(map(response => response.data as UserAdvisor[]));
  }

  public getMenu(): Observable<Menu[]> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/usuario/menu`).pipe(
      map((response: RestResponse) => response.data as Menu[])
    );
  }

  public getCategoryProfile(proofreaderId: number): Observable<CategoryProfile[]> {
    return this.http.get<RestResponse>(
      `${ this.baseUrl }/usuario/professor/${ proofreaderId }/perfilCategoria`
    ).pipe(map((response: RestResponse) => response.data as CategoryProfile[]));
  }

  public saveCategoryProfile(proofreaderId: number, profilesData: { perfilId: string, categorias: number[] }[]): Observable<void> {
    const data = profilesData.map(profile => ( {
      perfilId:   profile.perfilId,
      categorias: profile.categorias
    } ));

    return this.http.post<void>(
      `${ this.baseUrl }/usuario/professor/${ proofreaderId }/perfilCategoria`, data
    );
  }

}
