<section *ngIf="questionnaire"
         class="c-questionnaire">

  <aside *ngIf="!hideAside"
         class="c-questionnaire-aside">

    <dl class="o-data-list c-dashboard-content__summary u-margin-bottom-none">
      <ng-container *ngIf="questionnaire.datFinalizacao as completionDate">
        <dt class="o-data-list__title">Finalizada em:</dt>
        <dd class="o-data-list__item">{{ completionDate }}</dd>
      </ng-container>

      <ng-container *ngIf="questionnaire.nomCandidato">
        <dt class="o-data-list__title">Participante:</dt>
        <dd class="o-data-list__item">{{ questionnaire.candidatoId }} - {{ questionnaire.nomCandidato }}</dd>
      </ng-container>

      <ng-container *ngIf="questionnaire.datFinalizacao && questionnaire.qtdAcertos; else noScore">
        <dt class="o-data-list__title">Acertos:</dt>
        <dd class="o-data-list__item">
          <span class="c-pill t-alert-info u-margin-right-tiny">
            {{ questionnaire.percentual / 100 | percent:'1.0-2' }}
          </span>

          {{ questionnaire.qtdAcertos }} de
          {{ questionnaire.qtdQuestoes }} questões
        </dd>
      </ng-container>

      <ng-template #noScore>
        <ng-container *ngIf="!hideQuestions">
          <dt class="o-data-list__title">Quantidade de questões: </dt>
          <dd class="o-data-list__item">{{ questionnaire.qtdQuestoes || questionnaire.questoes?.length }}</dd>
        </ng-container>
      </ng-template>
    </dl>
  </aside>

  <div *ngIf="!hideQuestions"
       class="c-questionnaire__main">

    <form *ngIf="questionnaireForm"
          [formGroup]="questionnaireForm"
          class="o-form c-questionnaire-form">

      <ol class="c-questionnaire-questions"
          [class.o-list-bare]="hideNumbers"
          [class.o-list-ordered]="!hideNumbers"
          formArrayName="respostas">

        <li *ngFor="let question of questions; let questionIndex = index; let last = last"
            class="c-questionnaire-questions__item"
            [class.o-list-bare__item]="hideNumbers">

          <p class="c-questionnaire-questions__description"
             [innerHTML]="question.dscQuestao | safe:'html'">
          </p>

          <ng-container *ngIf="question" [formGroupName]="questionIndex">

            <mat-radio-group formControlName="alternativaId"
                             class="u-text-wrap o-form__control c-questionnaire-answers">

              <mat-radio-button *ngFor="let answerOption of question.alternativas"
                                class="c-questionnaire-answer__item"
                                [class.c-questionnaire-answers__item--correct]="isAdmin && answerOption.indAlternativaCorreta"
                                [value]="answerOption.alternativaId">

                <div [innerHTML]="answerOption.dscAlternativa | safe:'html'"></div>

                <ng-container *ngIf="isConcluded && isAdmin">
                  <fa-icon *ngIf="answerOption.indAlternativaCorreta"
                           [icon]="[ 'fas', 'check' ]"
                           class="u-icon t-accent u-margin-left-tiny">
                  </fa-icon>

                  <fa-icon *ngIf="answerOption.indAlternativaEscolhida && !correctAnswersMap.get(question.questaoId)"
                           [icon]="[ 'fas', 'times' ]"
                           class="u-icon t-alert-danger u-margin-left-tiny">
                  </fa-icon>
                </ng-container>
              </mat-radio-button>

            </mat-radio-group>

            <ng-container *ngIf="isConcluded && correctAnswersMap?.get(question.questaoId) !== null">
              <red-alert-box *ngIf="correctAnswersMap?.get(question.questaoId); else wrongAnswer"
                             class="t-alert-success u-margin-bottom-large"
                             [icon]="[ 'fas', 'check' ]"
                             title="Resposta correta">
              </red-alert-box>

              <ng-template #wrongAnswer>
                <red-alert-box class="t-alert-danger u-margin-bottom-large"
                               [icon]="[ 'fas', 'times' ]"
                               title="Resposta incorreta">
                </red-alert-box>
              </ng-template>
            </ng-container>

          </ng-container>

          <mat-divider *ngIf="!last" class="c-divider"></mat-divider>
        </li>

      </ol>

      <div *ngIf="!isConcluded"
           class="o-form__controls c-questionnaire-form__controls">

        <button mat-ripple
                type="button"
                class="c-button c-button--solid-reversed t-primary"
                (click)="onSaveClick($event)">

          <fa-icon class="u-margin-right-tiny"
                   [icon]="[ 'fas', 'save' ]">
          </fa-icon>

          Salvar
        </button>

        <button mat-ripple
                type="button"
                class="c-button c-button--solid t-primary"
                [disabled]="questionnaireForm.invalid || questionnaireForm.disabled"
                (click)="onConcludeClick($event)">

          <fa-icon class="u-margin-right-tiny"
                   [icon]="[ 'fas', 'check' ]">
          </fa-icon>

          Finalizar
        </button>

      </div>
    </form>

  </div>

</section>
