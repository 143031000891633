import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators }                            from '@angular/forms';

import { UserQuestionnaire }         from '@models/user/user-questionnaire';
import { UserQuestionnaireQuestion } from '@models/user/user-questionnaire-question';
import { userQuestionnaireType }     from '@models/user/user-questionnaire-type';
import { AnswerOption }              from '@models/proofreader/answerOption';

import { UserService } from '@services/user.service';
import { QuestionnaireAnswers } from '@models/proofreader/questionnaire-answers';


@Component({
  selector:    'red-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls:   [ './questionnaire.component.scss' ]
})
export class QuestionnaireComponent implements OnInit, OnChanges {

  public isAdmin:           boolean;
  public questions:         UserQuestionnaireQuestion[];
  public isConcluded:       boolean;
  public questionnaireForm: FormGroup;

  public correctAnswersMap: Map<number, boolean>;

  @Input() public isLoading:         boolean;
  @Input() public questionnaire:     UserQuestionnaire;
  @Input() public questionnaireType: userQuestionnaireType;
  @Input() public hideNumbers:       boolean;
  @Input() public hideAside:         boolean;
  @Input() public hideQuestions:     boolean;
 
  @Output() public saveClick:     EventEmitter<QuestionnaireAnswers> = new EventEmitter();
  @Output() public concludeClick: EventEmitter<QuestionnaireAnswers> = new EventEmitter();

  constructor(
    private formBuilder:  FormBuilder,
    private userService:  UserService,
  ) {
    this.correctAnswersMap = new Map<number, boolean>();
    this.saveClick         = new EventEmitter<QuestionnaireAnswers>();
    this.concludeClick     = new EventEmitter<QuestionnaireAnswers>();
  }

  ngOnInit(): void {
    this.checkUserType(); 
  }

  private checkUserType() {
    this.userService
        .isUserAdmin()
        .subscribe((isAdmin: boolean) => this.isAdmin = isAdmin);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['questionnaire'] && this.questionnaire) {
      this.questions   = this.questionnaire.questoes;
      this.isConcluded = Boolean(this.questionnaire.datFinalizacao);

      this.initForm();
    }

    if (changes && changes['isLoading']) {
      if (this.isLoading) {
        this.questionnaireForm?.disable();
      } else if (!this.isConcluded) {
        this.questionnaireForm?.enable();
      }
    }
  }

  private initForm() {
    if (this.hideQuestions) return;

    this.questionnaireForm = this.formBuilder.group({
      candidatoId: this.formBuilder.control(this.questionnaire.candidatoId),
      provaId:     this.formBuilder.control(this.questionnaire.provaId),
      respostas:   this.initQuestionsFormArray(this.questions)
    });

    if (this.isConcluded || this.isLoading) this.questionnaireForm.disable();
  }

  private initQuestionsFormArray(
    questions: UserQuestionnaireQuestion[]
  ): FormArray {
    const formArray: FormArray = this.formBuilder.array([]);

    if (!questions) return formArray;

    questions.forEach((question: UserQuestionnaireQuestion) => {
      if (this.isConcluded) {
        this.correctAnswersMap.set(question.questaoId, this.checkAnswer(question));
      }

      formArray.push(this.initQuestionFormGroup(question));
    });

    return formArray;
  }

  private initQuestionFormGroup(
    question: UserQuestionnaireQuestion
  ): FormGroup {
    const chosenAnswerId: number = this.getFlaggedAnswerId(question, 'indAlternativaEscolhida');

    return this.formBuilder.group({
      questaoId:     this.formBuilder.control(question?.questaoId || null),
      alternativaId: this.formBuilder.control(chosenAnswerId, [ Validators.required ])
    });
  }

  private getFlaggedAnswerId(
    question:     UserQuestionnaireQuestion,
    flagProperty: string
  ): number | null {
    if (!question?.alternativas) return null;
    const findOption = (option: AnswerOption) => Boolean(option[flagProperty]);
    return question.alternativas.find(findOption)?.alternativaId || null;
  }

  private checkAnswer(question: UserQuestionnaireQuestion): boolean | null {
    const chosenAnswerId: number  = this.getFlaggedAnswerId(question, 'indAlternativaEscolhida'),
          correctAnswerId: number = this.getFlaggedAnswerId(question, 'indAlternativaCorreta');

    if (!correctAnswerId) return null;

    return chosenAnswerId === correctAnswerId;
  }

  public onSaveClick(event: Event): void {
    if (event) event.preventDefault();
    this.saveClick.emit(this.questionnaireForm.getRawValue());
  }
  public onConcludeClick(event: Event) {
    if (event) event.preventDefault();
    if (this.questionnaireForm.invalid) return;
    this.concludeClick.emit(this.questionnaireForm.getRawValue());
  }

}
