import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse }  from '@angular/common/http';
import { DOCUMENT }           from '@angular/common';

import { Observable, of, throwError } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

import { environment }  from '@environment/environment';
import { ResolvedData } from '@models/utils/resolved-data';


@Injectable({ providedIn: 'root' })
export class ErrorService {

  private document: Document;

  constructor(
    private toastr: ToastrService,
    @Inject(DOCUMENT) private _document: any
  ) {
    this.document = this._document as Document;
  }

  public getErrorMessage(httpError: HttpErrorResponse): string {
    const httpErrorObj = httpError.error && httpError.error.error || httpError.error;

    if (typeof httpErrorObj === 'string') {
      return httpErrorObj;
    }

    if (httpErrorObj?.message) {
      return httpErrorObj.message;
    }

    if (httpErrorObj?.error) {
      return httpErrorObj.error.message || httpErrorObj.error.status;
    }
  }

  public displayErrorToast(httpError: HttpErrorResponse) {
    const httpErrorObj = httpError.error && httpError.error.error || httpError.error;

    if (typeof httpErrorObj === 'string') {
      this.toastr.error(httpErrorObj);
      return;
    }

    if (httpErrorObj) {
      const errorsArray = httpErrorObj.errors || [];

      for (const error of errorsArray) {
        this.toastr.warning(
          error,
          httpErrorObj.message || null,
          { timeOut: 10 * 1000 }
        );
      }

      if (httpErrorObj.error) {
        this.toastr.error(
          httpErrorObj.error.message,
          httpErrorObj.message || httpErrorObj.error.status
        );
      }

      if (httpErrorObj.message) {
        this.toastr.error(httpErrorObj.message);
      }
    }
  }

  public handleFormError(
    httpError: HttpErrorResponse,
    callback?: VoidFunction
  ) {
    if (httpError)                      this.displayErrorToast(httpError);
    if (typeof callback === 'function') callback();

    return throwError(() => httpError);
  }


  public handleResolverError(
    httpError: HttpErrorResponse,
    callback?: VoidFunction
  ): Observable<ResolvedData<any>> {

    if (httpError.status === 404) {
      this.document.location.href = `${ environment.legacyUrl }/404`;
    }

    this.displayErrorToast(httpError);

    if (typeof callback === 'function') callback();

    return of({ error: httpError });
  }
}
