import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { MaterialModule } from '../material/material.module';
import { AlertBoxModule } from '../alert-box/alert-box.module';
import { IconsModule } from '../icons/icons.module';
import { PipesModule } from '../pipes/pipes.module';

import { BlockProofreadModalComponent } from './block-proofread-modal/block-proofread-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { HelpComponent } from './help/help.component';
import { ImproperCheckModalComponent } from './improper-check-modal/improper-check-modal.component';
import { LightboxDirective } from './lightbox.directive';
import { LightboxComponent } from './lightbox/lightbox.component';
import { ReturnPendingModalComponent } from './return-pending-modal/return-pending-modal.component';
import { EditPacksDatesModalComponent } from './edit-packs-dates-modal/edit-packs-dates-modal.component';
import { EditBlockedObsModalComponent } from './edit-blocked-obs-modal/edit-blocked-obs-modal.component';
import { CreditControlStudentModalComponent } from './credit-control-student-modal/credit-control-student-modal.component';
import { BlockedObsHistoryModalComponent } from './blocked-obs-history-modal/blocked-obs-history-modal.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { EditPacksQueueModalComponent } from './edit-packs-queue-modal/edit-packs-queue-modal.component';
import { EditPacksQtdModalComponent } from './edit-packs-qtd-modal/edit-packs-qtd-modal.component';
import { DetailBlockedModalComponent } from './detail-blocked/detail-blocked-modal.component';
import { BlockedCreditBackComponent } from './blocked-credit-back/blocked-credit-back.component';
import { WarningsModalComponent } from './warnings-modal/warnings-modal.component';
import { UpdatePackageFormAnswerModalComponent } from './update-package-form-answer-modal/update-package-form-answer-modal.component';
import { SelectDateModalComponent } from './select-date-modal/select-date-modal.component';
import { BatchEditScheduleFormModalComponent } from './batch-edit-schedule-form-modal/batch-edit-schedule-form-modal.component';
import { ObservationModalComponent } from './observation-modal/observation-modal.component';
import { NewTaskBasicModalComponent } from './new-task-basic/new-task-basic-modal.component';
import { GenreEditModalComponent } from './genre-edit-modal/genre-edit-modal.component';
import { SuspendProofreaderModalComponent } from './suspend-proofreader-modal/suspend-proofreader-modal.component';
import { GenerateExamModal } from './generate-exam-modal/generate-exam-modal.component';
import { SelectTrainingModalComponent } from './select-training-modal/select-training-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { ComplaintSchoolModalComponent } from './complaint-school-modal/complaint-school-modal.component';
import { RollbackProofreadModalComponent } from './rollback-proofread-modal/rollback-proofread-modal.component';
import { AdaptiveLearningSurveyModalComponent } from './adaptive-learning-survey-modal/adaptive-learning-survey-modal.component';
import { QuestionnaireModule } from '@shared/questionnaire/questionnaire.module';
import { AlertHistoryModalComponent } from './alert-history-modal/alert-history-modal.component';


@NgModule({
  declarations: [
    BlockProofreadModalComponent,
    ConfirmationModalComponent,
    ObservationModalComponent,
    HelpComponent,
    ImproperCheckModalComponent,
    LightboxDirective,
    LightboxComponent,
    ReturnPendingModalComponent,
    EditPacksDatesModalComponent,
    EditBlockedObsModalComponent,
    CreditControlStudentModalComponent,
    BlockedObsHistoryModalComponent,
    ChangePasswordModalComponent,
    EditPacksQueueModalComponent,
    EditPacksQtdModalComponent,
    DetailBlockedModalComponent,
    BlockedCreditBackComponent,
    WarningsModalComponent,
    UpdatePackageFormAnswerModalComponent,
    SelectDateModalComponent,
    BatchEditScheduleFormModalComponent,
    NewTaskBasicModalComponent,
    GenreEditModalComponent,
    SuspendProofreaderModalComponent,
    GenerateExamModal,
    SelectTrainingModalComponent,
    InfoModalComponent,
    ComplaintSchoolModalComponent,
    RollbackProofreadModalComponent,
    AdaptiveLearningSurveyModalComponent,
    AlertHistoryModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipesModule,
    AlertBoxModule,
    IconsModule,
    EditorModule,
    QuestionnaireModule
  ],
  exports: [
    BlockProofreadModalComponent,
    ConfirmationModalComponent,
    ObservationModalComponent,
    HelpComponent,
    ImproperCheckModalComponent,
    LightboxDirective,
    LightboxComponent,
    ReturnPendingModalComponent,
    EditPacksDatesModalComponent,
    EditBlockedObsModalComponent,
    CreditControlStudentModalComponent,
    BlockedObsHistoryModalComponent,
    ChangePasswordModalComponent,
    EditPacksQueueModalComponent,
    WarningsModalComponent,
    UpdatePackageFormAnswerModalComponent,
    SelectDateModalComponent,
    BatchEditScheduleFormModalComponent,
    SuspendProofreaderModalComponent,
    RollbackProofreadModalComponent,
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class ModalsModule { }
