<article class="c-modal c-confirm-modal">

  <h1 mat-dialog-title class="c-modal__title">
    {{ title }}
  </h1>

  <section mat-dialog-content class="u-margin-bottom-small u-padding-horizontal u-padding-vertical-small">
    <p [innerHTML]="body | safe:'html'" class="u-margin-bottom"></p>

  
    <ng-container *ngIf="questionnaire else loading;">
      <red-questionnaire 
        [questionnaire]="questionnaire"
        [questionnaireType]="questionnaire?.grupoQuestao"
        [hideAside]="true" 
        [isLoading]="(isLoading$ | async)"
        (saveClick)="saveQuestionnaire($event)"
        (concludeClick)="concludeQuestionnaire($event)">
      </red-questionnaire>
    </ng-container>

    <ng-template #loading>
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </ng-template>

  </section>

  
  <footer mat-dialog-actions class="t-primary c-adaptive-learning-survey-modal__footer c-modal__footer">
    <!--suppress HtmlUnknownAttribute -->
    <button mat-ripple
            cdkFocusInitial
            class="c-button c-button--solid-reversed"
            [mat-dialog-close]="false">
      {{ cancelBtn }}
    </button>
 
  </footer>
  
</article>
