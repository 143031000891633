<div class="c-alert-box o-media o-media--small"
     [ngClass]="'c-alert-box--' + size">

  <div class="o-media__img" *ngIf="!!icon">
    <fa-icon class="c-alert-box__icon"
             [icon]="icon"
             [fixedWidth]="true">
    </fa-icon>
  </div>

  <div class="o-media__body">
    <h4 *ngIf="title" class="c-alert-box__title" [innerText]="title"></h4>

    <div *ngIf="body" [innerHTML]="body | safe:'html'"></div>
  </div>
</div>
