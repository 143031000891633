import { environment } from '@environment/environment';
import { Anchor }      from '@models/navigation/anchor';


export const RED_LINKS: { [slug: string]: Anchor } = {
  'students-home':                {
    text:       'Para alunos',
    title:      'Home institucional de alunos',
    slug:       'students-home',
    routerLink: [ '/home' ],
    fragment:   'inicio'
  },
  'students-how':                 {
    text:       'Como funciona',
    title:      'Como funciona para alunos',
    slug:       'students-how',
    id:         'btn-mtop-comofunciona',
    routerLink: [ '/home' ],
    fragment:   'como-funciona'
  },
  'students-pricing':             {
    text:       'Preço',
    title:      'Preços e pacotes',
    slug:       'students-pricing',
    id:         ' btn-mtop-preco',
    routerLink: [ '/home' ],
    fragment:   'preco'
  },
  'pack-quero-experimentar':      {
    text:         'Quero Experimentar',
    title:        'Compre agora, pacote Quero Experimentar',
    slug:         'pack-quero-experimentar',
    externalLink: `${ environment.legacyUrl }/pagamento/pagarme/18`
  },
  'pack-quero-melhorar':          {
    text:         'Quero Melhorar',
    title:        'Compre agora, pacote Quero Melhorar',
    slug:         'pack-quero-melhorar',
    externalLink: `${ environment.legacyUrl }/pagamento/pagarme/22`
  },
  'pack-quero-arrasar':           {
    text:         'Quero Arrasar',
    title:        'Compre agora, pacote Quero Arrasar',
    slug:         'pack-quero-arrasar',
    externalLink: `${ environment.legacyUrl }/pagamento/pagarme/23`
  },
  'pack-nota-1000':               {
    text:         'Plano Nota 1000',
    title:        'Compre agora, plano Nota 1000',
    slug:         'pack-nota-1000',
    externalLink: `${ environment.legacyUrl }/pagamento/pagarme/24`
  },
  'schools-home':                 {
    text:       'Para escolas',
    title:      'Home institucional de escolas',
    slug:       'schools-home',
    id:         'btn-mtop-escola',
    routerLink: [ '/home/para-escolas' ],
    fragment:   'inicio'
  },
  'schools-resources':            {
    text:       'Recursos',
    title:      'Recursos exclusivos para escolas',
    slug:       'schools-resources',
    routerLink: [ '/home/para-escolas' ],
    fragment:   'recursos-exclusivos'
  },
  'schools-pilot':                {
    text:       'Experimente',
    title:      'Solicite um projeto piloto para sua escola',
    slug:       'schools-pilot',
    routerLink: [ '/home/para-escolas' ],
    fragment:   'piloto'
  },
  'about':                        {
    text:       'Sobre',
    title:      'Sobre a Redação Nota 1000',
    slug:       'about',
    routerLink: [ '/home/sobre' ],
    fragment:   'inicio'
  },
  'terms-of-service':             {
    text:       'Termos de uso',
    slug:       'terms-of-service',
    routerLink: [ '/home/termos-de-uso' ]
  },
  'privacy-policy':               {
    text:       'Política de privacidade',
    slug:       'privacy-policy',
    routerLink: [ '/home/politica-de-privacidade' ]
  },
  'marathon':                     {
    text:       'Maratona 2022',
    slug:       'marathon',
    routerLink: [ '/maratona' ]
  },
  'marathon-ranking-students':                     {
    text:       'Ranking alunos',
    slug:       'marathon-ranking-students',
    routerLink: [ '/maratona/ranking/alunos' ]
  },
  'marathon-ranking-schools':                     {
    text:       'Ranking escolas',
    slug:       'marathon-ranking-schools',
    routerLink: [ '/maratona/ranking/escolas' ]
  },
  // 'materials-for-schools':        {
  //   text:         'Materiais para Escolas',
  //   slug:         'materials-for-schools',
  //   target:       '_blank',
  //   externalLink: `https://materiais.redacaonota1000.com.br/para-escolas`
  // },
  // 'materials-for-students':       {
  //   text:         'Materiais para Alunos',
  //   slug:         'materials-for-students',
  //   target:       '_blank',
  //   externalLink: `https://materiais.redacaonota1000.com.br/para-alunos`
  // },
  'become-a-proofreader':             {
    text:       'Seja um corretor de redações',
    slug:       'be-a-proofreader',
    routerLink: [ '/home/registro-candidato' ]
    // target:       '_blank',
    // externalLink: `https://materiais.redacaonota1000.com.br/seja-um-corretor-da-redacao-nota-1000-captacao-2022`
  },
  'profiles-hub':                 {
    text:       'Meus dados',
    slug:       'profiles-hub',
    routerLink: [ '/cadastro/perfil' ]
  },
  'profile-proofreader':          {
    text:       'Meus dados',
    slug:       'profile-proofreader',
    routerLink: [ '/cadastro/corretor/perfil' ]
  },
  'profile':                      {
    text:         'Meus dados',
    slug:         'profile',
    externalLink: `${ environment.legacyUrl }/cadastro/perfil`
  },
  'change-password':              {
    text:                'Alterar senha',
    slug:                'change-password',
    // externalLink: `${ environment.legacyUrl }/cadastro/alterarSenha`
    routerLink:          [ ],
    queryParams:         { trocarSenha: true },
    queryParamsHandling: 'merge'
  },
  'contact':                      {
    text:       'Contato',
    slug:       'contact',
    routerLink: [ '/home/contato' ],
    fragment:   'inicio'
    // externalLink: `${environment.legacyUrl}/contato/contatoInterno`
  },
  'use-terms': {
    text:       'Termos de uso',
    slug:       'use-terms',
    routerLink: [ '/cadastro/corretor/termos-de-uso' ],
  },
  'contact-logged':               {
    text:       'Fale com a gente',
    slug:       'contact-logged',
    routerLink: [ '/usuario/contato' ],
    fragment:   'inicio'
  },
  'logout':                       {
    text:         'Logout',
    slug:         'logout',
    externalLink: `${ environment.legacyUrl }/logout.servlet`
  },
  'legacy-entry':                 {
    text:         'home',
    slug:         'legacy-entry',
    externalLink: `${ environment.legacyUrl }/home/homeLogada`
  },
  'essays-list':                  {
    text:       'Minhas Redações',
    slug:       'essays-list',
    routerLink: [ '/redacao/lista' ]
    // externalLink: `${ environment.legacyUrl }/redacao/list`
  },
  'student-progress':             {
    text:       'Minha Evolução',
    slug:       'student-progress',
    routerLink: [ '/dash/aluno' ]
  },
  'themes-database':              {
    text:       'Banco de temas',
    slug:       'themes-database',
    routerLink: [ '/tema/listaTema' ]
    // externalLink: `${ environment.legacyUrl }/tema/listaTema`
  },
  'credits-store-legacy':         {
    text:         'Comprar créditos',
    slug:         'credits-store',
    externalLink: `${ environment.legacyUrl }/pacote/list`
  },
  'credits-store':                {
    text:       'Comprar créditos',
    slug:       'credits-store',
    routerLink: [ '/usuario/precos' ]
  },
  'credits-statement':            {
    text:         'Extrato de créditos',
    slug:         'credits-statement',
    externalLink: `${ environment.legacyUrl }/credito/list`
  },
  'video-tutorials':              {
    text:         'Vídeos tutoriais',
    slug:         'video-tutorials',
    externalLink: `${ environment.legacyUrl }/tutorial/tutorial`
  },
  'new-task':                     {
    text:       'Criar tarefa de redação',
    slug:       'new-task',
    routerLink: [ '/tarefa/nova' ]
    // externalLink: `${ environment.legacyUrl }/tarefa/cadastroTarefa`
  },
  'tasks-list':                   {
    text:       'Tarefas',
    slug:       'tasks-list',
    routerLink: [ '/tarefa/listaTarefa' ]
  },
  'tasks-list-follow':            {
    text:       'Acompanhar tarefas',
    slug:       'tasks-list',
    routerLink: [ '/tarefa/listaTarefa' ]
  },
  'mock-essays-list':             {
    text:         'Aplicar simulado',
    slug:         'mock-essays-list',
    externalLink: `${ environment.legacyUrl }/simulado/lista`
  },
  'results-by-student':           {
    text:       'Por aluno',
    title:      'Ver resultados por aluno',
    slug:       'results-by-student',
    routerLink: [ '/redacao/aluno' ]
    // externalLink: `${ environment.legacyUrl }/painel/exibe/aluno`
  },
  'results-by-school':            {
    text:       'Resultado escola',
    title:      'Ver resultados por escola',
    slug:       'results-by-school',
    routerLink: [ '/dash/evolucao' ]
    // externalLink: `${ environment.legacyUrl }/painel/exibe`,
  },
  'results-by-school-or-class':   {
    text:       'Por escola ou turma',
    title:      'Ver resultados por escola ou turma',
    slug:       'results-by-school-or-class',
    routerLink: [ '/dash/evolucao' ]
    // externalLink: `${ environment.legacyUrl }/painel/exibe`,
  },
  'results-by-task':              {
    text:       'Por tarefa',
    title:      'Ver resultados por tarefa',
    slug:       'results-by-task',
    routerLink: [ '/tarefa/listaTarefa' ]
  },
  'results-by-mock-test':         {
    text:         'Por avaliação/simulado',
    title:        'Ver resultados por avaliação/simulado',
    slug:         'results-by-mock-test',
    externalLink: `${ environment.legacyUrl }/simulado/lista`
  },
  'export-scores':                {
    text:       'Extrair notas (excel)',
    slug:       'export-scores',
    routerLink: [ '/relatorio/notas' ]
    // externalLink: `${ environment.legacyUrl }/relatorio/notas`
  },
  'monitoring-report':                {
    text:       'Monitorias',
    slug:       'monitoring-report',
    routerLink: [ '/relatorio/monitorias' ]
    // externalLink: `${ environment.legacyUrl }/relatorio/notas`
  },
  'proofreader-monitoring-list':                {
    text:       'Minhas monitorias',
    slug:       'proofreader-monitoring-list',
    routerLink: [ '/dash/monitorias' ]
  },
  'sign-up-classes-and-students': {
    text:       'Turmas e alunos',
    title:      'Cadastrar turmas e alunos',
    slug:       'sign-up-classes-and-students',
    routerLink: [ '/cadastro/turmas' ]
    // externalLink: `${ environment.legacyUrl }/cadastro/turma/exibe`,
  },
  'sign-up-theme-school':         {
    text:       'Temas',
    title:      'Cadastrar temas',
    slug:       'sign-up-theme-school',
    routerLink: [ '/tema/escola' ]
    // externalLink: `${ environment.legacyUrl }/cadastro/tema/listaTemaEscola`,
  },
  'proofreading-list':            {
    text:       'Minhas Correções',
    slug:       'proofreading-list',
    routerLink: [ '/correcao' ]
    // externalLink: `${ environment.legacyUrl }/redacao/correcao/list`
  },
  'new-proofread':                {
    text:         'Fazer nova correção',
    slug:         'new-proofread',
    externalLink: `${ environment.legacyUrl }/redacao/correcao`
  },
  'proofreading-tracking':        {
    text:         'Acompanhamento',
    slug:         'proofreading-tracking',
    externalLink: `${ environment.legacyUrl }/corretor/acompanhamento/detalhe`
  },
  'proofreading-guides':          {
    text:       'Manuais de correção',
    slug:       'proofreading-guides',
    routerLink: [ '/manuais' ]
  },
  'proofreading-support-materials':          {
    text:       'Materiais de apoio',
    slug:       'proofreading-support-materials',
    routerLink: [ '/manuais/materiais-de-apoio' ]
  },
  'calibration':                  {
    text:       'Calibragem',
    slug:       'calibration',
    routerLink: [ '/dash/calibragem/usuario' ]
    // externalLink: `${ environment.legacyUrl }/calibragem/resultado`
  },
  'admin-dashboard':              {
    text:       'Dashboard admin',
    slug:       'admin-dashboard',
    routerLink: [ '/dash/admin' ]
  },
  'admin-engagement-brands':             {
    text:       'Engajamento (Selos)',
    slug:       'admin-engagement-brands',
    routerLink: [ '/dash/admin/engajamento' ]
  },
  'admin-engagement-schools':             {
    text:       'Engajamento (Escolas)',
    slug:       'admin-engagement-schools',
    routerLink: [ '/dash/admin/engajamento/escolas' ]
  },
  'advisor-dashboard':            {
    text:       'Dashboard assessor',
    slug:       'advisor-dashboard',
    routerLink: [ '/dash/assessor' ]
  },
  'advisor-engagement':           {
    text:       'Relatório de Engajamento',
    slug:       'advisor-engagement',
    routerLink: [ '/dash/assessor/engajamento' ]
  },
  'admin-sign-up-schools':        {
    text:       'Escolas',
    title:      'Cadastrar escolas',
    slug:       'admin-sign-up-schools',
    routerLink: [ '/cadastro/escolas' ]
    // externalLink: `${ environment.legacyUrl }/admin/listaEscola`,
  },
  'sign-up-coordinator':          {
    text:         'Coordenadores',
    title:        'Cadastrar coordenadores',
    slug:         'sign-up-coordinator',
    externalLink: `${ environment.legacyUrl }/cadastro/coordenador/listaCoordenador`
  },
  'admin-sign-up-proofreader':    {
    text:  'Corretor',
    title: 'Cadastrar corretor',
    slug:  'admin-sign-up-proofreader',
    // externalLink: `${ environment.legacyUrl }/admin/corretor/listaCorretor`
    routerLink: [ '/cadastro/corretores' ]
  },
  'admin-sign-up-advisor':    {
    text:  'Assessor',
    title: 'Cadastrar assessor',
    slug:  'admin-sign-up-advisor',
    routerLink: [ '/cadastro/assessores' ]
  },
  'sign-up-theme':                {
    text:       'Temas',
    title:      'Cadastrar temas',
    slug:       'sign-up-theme',
    routerLink: [ '/tema/admin' ]
    // externalLink: `${ environment.legacyUrl }/cadastro/tema/lista`
  },
  'sign-up-debatema':                {
    text:       'Debatema',
    title:      'Cadastrar debatema',
    slug:       'sign-up-debatema',
    routerLink: [ '/cadastro/banner-debatema' ]
  },
  'admin-proofreaders-dashboard': {
    text:       'Dash Corretores',
    slug:       'admin-proofreaders-dashboard',
    routerLink: [ '/correcao/admin' ]
    // externalLink: `${ environment.legacyUrl }/dash/qualidade/acompanha`,
  },
  'quality-dashboard':            {
    text:         'Dash Qualidade',
    slug:         'quality-dashboard',
    externalLink: `${ environment.legacyUrl }/dash/qualidade/acompanha`
  },
  'quality-monitoring':           {
    text:         'Monitoramento',
    slug:         'quality-monitoring',
    externalLink: `${ environment.legacyUrl }/monitoramento/qualidade`
  },
  'proofreading-quality':         {
    text:         'Qualidade de correções',
    slug:         'proofreading-quality',
    externalLink: `${ environment.legacyUrl }/admin/corretor/list`
  },
  'proofreaders-payment':         {
    text:         'Pagamento de corretores',
    slug:         'proofreaders-payment',
    externalLink: `${ environment.legacyUrl }/admin/corretor/realizaPagamento`
  },
  'queue-management-old':             {
    text:         'Gerenciamento de filas',
    title:        'Gerenciar filas de correções',
    slug:         'queue-management',
    externalLink: `${ environment.legacyUrl }/admin/gerenciamentoFilaCorrecao`
  },
  'queue-management':             {
    text:         'Gerenciamento de filas',
    title:        'Gerenciar filas de correções',
    slug:         'queue-management',
    routerLink:   [ '/correcao/filas' ]
  },
  'essay-packages':               {
    text:       'Pacotes de redação',
    slug:       'essay-packages',
    routerLink: [ '/correcao/pacotes' ]
    // externalLink: `${ environment.legacyUrl }/correcao/fila/pacote/lista`
  },
  'proofreader-report':           {
    text:         'Relatório Corretor',
    slug:         'proofreader-report',
    externalLink: `${ environment.legacyUrl }/corretor/acompanhamento/lista`
  },
  'mock-control':                 {
    text:         'Controle de simulados',
    slug:         'mock-control',
    externalLink: `${ environment.legacyUrl }/controleSimulado`
  },
  'mock-control-old':             {
    text:         'Controle (antigo)',
    title:        'Controle de simulados (antigo)',
    slug:         'mock-control-old',
    externalLink: `${ environment.legacyUrl }/simulado/listaSimuladoNova`
  },
  'sheet-section':                {
    text:         'Corte da folha',
    slug:         'sheet-section',
    externalLink: `${ environment.legacyUrl }/simulado/controle`
  },
  'customer-service-school':      {
    text:         'Escola',
    slug:         'customer-service-school',
    externalLink: `${ environment.legacyUrl }/atendimento/escola/painel`
  },
  'training-dashboard':      {
    text:       'Painel',
    slug:       'training-dashboard',
    routerLink: [ '/dash/treinamentos/painel' ]
  },
  'calibration-list':             {
    text:         'Calibragens',
    slug:         'calibration-list',
    routerLink:   [ '/dash/calibragens' ]
  },
  'new-training':                 {
    text: 'Cadastro de Treinamento',
    slug: 'new-training',
    // externalLink: `${ environment.legacyUrl }/treinamento/lista`
    routerLink: [ '/dash/treinamentos' ]
  },
  'new-guide':                    {
    text:       'Cadastro Manual',
    slug:       'new-guide',
    routerLink: [ '/manuais' ]
    // externalLink: `${ environment.legacyUrl }/manual/listaManual`
  },
  'new-guide-item':               {
    text:       'Cadastro Item manual',
    slug:       'new-guide-item',
    routerLink: [ '/manuais/itens' ]
    // externalLink: `${ environment.legacyUrl }/manual/listaItemManual`
  },
  'new-support':                    {
    text:       'Materiais de Apoio',
    slug:       'new-support',
    routerLink: [ '/manuais/materiais-de-apoio' ]
    // externalLink: `${ environment.legacyUrl }/manual/listaManual`
  },
  'new-support-item':               {
    text:       'Itens de Material de Apoio',
    slug:       'new-support-item',
    routerLink: [ '/manuais/materiais-de-apoio/itens' ]
    // externalLink: `${ environment.legacyUrl }/manual/listaItemManual`
  },
  'edit-schedule-form':               {
    text:       'Formulários de Programação',
    slug:       'edit-schedule-form',
    routerLink: [ '/programacao/cadastro-formulario' ]
  },
  'schedule-form-answers':               {
    text:       'Programação de Pacotes',
    slug:       'schedule-form-answers',
    routerLink: [ '/programacao/respostas' ]
  },
  'schedule-form':               {
    text:       'Programação de Pacotes',
    slug:       'schedule-form',
    routerLink: [ '/programacao/formularios' ]
  },
  'purchases-report':                 {
    text:       'Compras',
    slug:       'purchases-report',
    routerLink: [ '/relatorio/compras' ]
  },
  'new-record-model':             {
    text:         'Cadastro Modelo Prontuário',
    slug:         'new-record-model',
    externalLink: `${ environment.legacyUrl }/prontuario/listaModelo`
  },
  'credit-orders-list':           {
    text:         'Pedido',
    slug:         'credit-orders-list',
    externalLink: `${ environment.legacyUrl }/admin/listaPedido`
  },
  'transfer-free-credits':        {
    text:         'Transferir créditos livres',
    slug:         'transfer-free-credits',
    externalLink: `${ environment.legacyUrl }/controle/credito/exibe`
  },
  'transfer-student-credits':     {
    text:         'Transferir créditos aluno',
    slug:         'transfer-student-credits',
    externalLink: `${ environment.legacyUrl }/controle/credito/aluno/exibe`
  },
  'subscriptions-report':         {
    text:         'Relatório Assinaturas',
    slug:         'subscriptions-report',
    externalLink: `${ environment.legacyUrl }/controle/credito/relatorio/assinatura`
  },
  'student-report':               {
    text:         'Aluno',
    slug:         'student-report',
    externalLink: `${ environment.legacyUrl }/admin/relatorioAluno`
  },
  'theme-report':                 {
    text:         'Por tema',
    slug:         'theme-report',
    externalLink: `${ environment.legacyUrl }/coordenador/acompanhamento`
  },
  'scores-report':                {
    text:  'Extrair notas',
    title: 'Relatório de notas',
    slug:  'scores-report',
    // externalLink: `${ environment.legacyUrl }/relatorio/notas`
    routerLink: [ '/relatorio/notas' ]
  },
  'scores-report-proofreader':                {
    text:  'Extrair notas corretor',
    title: 'Relatório de notas corretor',
    slug:  'scores-report-proofreader',
    // externalLink: `${ environment.legacyUrl }/relatorio/notas`
    routerLink: [ '/relatorio/notas-corretor' ]
  },
  'proofreader-payment-report':   {
    text:       'Pagamento por corretor',
    title:      'Relatório financeiro por corretor',
    slug:       'proofreader-payment-report',
    routerLink: [ '/relatorio/pagamento/corretor' ]
  },
  'generate-rpa':                 {
    text:       'Arquivos RPA',
    title:      'Gerar arquivos RPA',
    slug:       'generate-rpa',
    routerLink: [ '/dash/rpa' ]
  },
  'admin-proofreader-payment-report':         {
    text:       'Pagamento corretores',
    title:      'Relatório financeiro geral',
    slug:       'admin-payment-report',
    routerLink: [ '/relatorio/pagamento/admin' ]
  },
  'schools-payment-report':   {
    text:       'Pagamento por escolas',
    title:      'Relatório financeiro por escola',
    slug:       'proofreader-payment-report',
    routerLink: [ '/relatorio/pagamento-escolas/escola' ]
  },
  'admin-schools-payment-report':         {
    text:       'Pagamento escola',
    title:      'Relatório financeiro geral',
    slug:       'admin-payment-report',
    routerLink: [ '/relatorio/pagamento-escolas/admin' ]
  },
  'ban-list-report':              {
    text: 'Controle de barrados',
    slug: 'ban-list-report',
    // externalLink: `${ environment.legacyUrl }/barrado/listaRedacaoBarrado`
    routerLink: [ '/correcao/barrados' ]
  },
  'users-report':                 {
    text:       'Usuários',
    slug:       'users-report',
    routerLink: [ '/relatorio/usuarios' ]
  },
  'proofreaders-report':                 {
    text:       'Corretores',
    slug:       'proofreaders-report',
    routerLink: [ '/relatorio/corretores' ]
  },
  'postback':                     {
    text:         'Postback',
    slug:         'postback',
    externalLink: `${ environment.legacyUrl }/admin/redacao/postback/lista`
  },
  'partner-access':               {
    text:         'Acesso parceiro',
    slug:         'partner-access',
    externalLink: `${ environment.legacyUrl }/parceiro/acesso`
  },
  'jobs':                         {
    text:         'Jobs',
    slug:         'jobs',
    externalLink: `${ environment.legacyUrl }/job/list`
  },
  'proofreading-credits':         {
    text:         'Créditos de correção',
    slug:         'proofreading-credits',
    externalLink: `${ environment.legacyUrl }/controle/credito/list`
  },
  'diagnostic-questionnaire':                 {
    text:       'Avaliação diagnóstica',
    slug:       'diagnostic-questionnaire',
    routerLink: [ '/avaliacao/diagnostica' ]
  },
  'socio-economic-questionnaire':                 {
    text:       'Avaliação socioeconômica',
    slug:       'socio-economic-questionnaire',
    routerLink: [ '/avaliacao/socio-economica' ]
  },
  'bug-report':                 {
    text:       'Suporte TI',
    slug:       'bug-report',
    routerLink: []
  },
};
