import { Component, Input } from '@angular/core';

import { IconProp } from '@fortawesome/fontawesome-svg-core';


export type AlertBoxSizes = 'small' | 'medium' | 'large';

@Component({
  selector:    'red-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls:   [ './alert-box.component.scss' ]
})
export class AlertBoxComponent {

  @Input() public icon:  IconProp;
  @Input() public size:  AlertBoxSizes;
  @Input() public body:  string;
  @Input() public title: string;

  constructor() {
    this.size = 'small';
  }
}
