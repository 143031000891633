import { Injectable }             from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { catchError }      from 'rxjs/operators';

import { environment }           from '@environment/environment';
import { RestResponse }          from '@models/rest';
import { QuestionnaireAnswers }  from '@models/proofreader/questionnaire-answers';
import { userQuestionnaireType } from '@models/user/user-questionnaire-type';
import { UserQuestionnaire }     from '@models/user/user-questionnaire';

import { TransformClass } from '@utils/transform';
import { ErrorService }   from '@utils/error/error.service';


@Injectable({ providedIn: 'root' })
export class ApiUserQuestionnaireService {

  private readonly baseUrl: string;

  constructor(
    private http:         HttpClient,
    private errorService: ErrorService
  ) {
    this.baseUrl = `${ environment.apiUrl }/usuario/avaliacao`;
  }

  public getUserQuestionnaire(
    questionnaireType: userQuestionnaireType
  ): Observable<UserQuestionnaire> {
    const httpParams: HttpParams = TransformClass.httpParams({ grupoQuestao: questionnaireType });

    return this.http.get<RestResponse>(`${ this.baseUrl }`, { params: httpParams })
               .pipe(map(response => response.data as UserQuestionnaire));
  }

  public getQuestionnaire(
    questionnaireId: number
  ): Observable<UserQuestionnaire> {
    return this.http.get<RestResponse>(`${ this.baseUrl }/${ questionnaireId }`)
               .pipe(map(response => response.data as UserQuestionnaire));
  }

  public saveQuestionnaire(
    questionnaireId: number,
    answers:         QuestionnaireAnswers
  ): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/${ questionnaireId }`, answers);
  }

  public concludeQuestionnaire(
    questionnaireId: number,
    answers:         QuestionnaireAnswers
  ): Observable<void> {
    return this.http.post<void>(`${ this.baseUrl }/${ questionnaireId }/finalizacao`, answers);
  }

  public submitQuestionnaire(
    id:             number,
    answers:        QuestionnaireAnswers,
    shouldConclude: boolean,
    errorCallback?: VoidFunction
  ): Observable<void> {

    const endpoint$: Observable<void> = shouldConclude
                                        ? this.concludeQuestionnaire(id, answers)
                                        : this.saveQuestionnaire(id, answers);

    return endpoint$.pipe(catchError((error) => {
      return this.errorService.handleFormError(error, errorCallback);
    }));
  }

  public getQuestionnaireFeedback(): Observable<UserQuestionnaire>{
    return this.http.get<RestResponse>(`${ this.baseUrl }/feedback`)
    .pipe(map(response => response.data as UserQuestionnaire))
  }

}
